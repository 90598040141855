import { useState, useEffect } from "react";

const useFormValidation = (initialState, validate, onSubmit) => {
    const [values, setValues] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
  
    const handleChange = (event) => {
      const { name, value } = event.target;
      setValues({
        ...values,
        [name]: value,
      });

     // Validate the specific input field on change
     const fieldErrors = validate({ ...values, [name]: value });
     setErrors((prevErrors) => ({
       ...prevErrors,
       [name]: fieldErrors[name],
     }));
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      const validationErrors = validate(values);
      setErrors(validationErrors);
      setIsSubmitting(true);
    };
  
    useEffect(() => {
      if (isSubmitting && Object.keys(errors).length === 0) {
        onSubmit();
        setIsSubmitting(false);
      } 
    }, [errors, isSubmitting, onSubmit]);
  
    return {
      values,
      errors,
      handleChange,
      handleSubmit
    };
  };

export default useFormValidation;