import styled from "styled-components";

export const Wrapper = styled.div`
    .countdown {
        display: flex;
        justify-content: center;
        gap: calc(var(--baseSize) * 4);
    }
`;
export const Content = styled.div`
    .time {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: var(--white);
        gap: calc(var(--baseSize) * 1);
        span:first-child {
            font-size: 3em;
            font-weight: var(--baseWeight);

            @media (min-width: 768px) {
                font-size: 5em;
            }
        }
        span:last-child {
            
            font-style: normal;

            @media (min-width: 768px) {
                font-size: 2rem;
                font-weight: calc(var(--baseWeight) * 3);
            }
        }
    }

`;