import React from "react";

//Styles
import { Wrapper } from "./Container.styles";  

const Container = ({ children }) => (
        <Wrapper>
            {children}
        </Wrapper>
    );

  
export default Container;