import React from "react";
//hooks
import useCountdown from '../../hooks/useCountdown';

//styles
import { Wrapper, Content } from "./Countdown.styles";

const Countdown = () => {
    const targetDate = 'Dec 31, 2025 00:00:00';
    const time = useCountdown(targetDate);

    return (
        <Wrapper>
        <Content>
            <div className="countdown" id="countdown">
                <div className="time">
                    <span className="days">{time.days}</span>
                    <span>Days</span>
                </div>
                <div className="time">
                    <span className="hours">{time.hours}</span>
                    <span>Hours</span>
                </div>
                <div className="time">
                    <span className="minutes">{time.minutes}</span>
                    <span>Minutes</span>
                </div>
                <div className="time">
                    <span className="seconds">{time.seconds}</span>
                    <span>Seconds</span>
                </div>
            </div>
        </Content>
    </Wrapper>
);

};

export default Countdown;