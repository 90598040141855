import styled from 'styled-components';

const LoadingSpinner = styled.div`
  border: 4px solid var(--medGrey);
  border-top: 4px solid var(--lightGrey);
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 24px;
  height: 24px;
  animation: spin 0.8s linear infinite;
  margin: 0px auto;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export default LoadingSpinner;