import React, { useEffect, useState } from "react";
//styles
import { Wrapper, Content } from "../AllRightsReserved/AllRightsReserved.styled";

const AllRightsReserved = () => {
    const [year, setYear] = useState(new Date().getFullYear());
    useEffect(() => {
        setYear(new Date().getFullYear());
    }, []);

    return(
        <Wrapper>
            <Content>
                © {year} All Rights Reserved. Developed by @rbonney.
            </Content>
        </Wrapper>
    );
}
export default AllRightsReserved;