import styled from 'styled-components';

export const Title = styled.h1`
    text-align: center;
    color: var(--white);
    font-size: 9em;
    font-weight: var(--baseWeight);
    margin-bottom: calc(var(--baseSize) * 10);

    @media (max-width: 768px) {
        font-size: 5em;
    }
`;

export const Subtitle = styled.p`
    text-align: center;
    color: var(--white);
    font-size: 1.6rem;
    margin-bottom: calc(var(--baseSize)* 8);
    font-weight: calc(var(--baseWeight) * 3);
    font-style: normal;
`;
