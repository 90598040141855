import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

//components
import TitleComponent from './components/Title';
import Container from './components/Container';
import Countdown from './components/Countdown';
import Form from './components/Form';
import AllRightsReserved from './components/AllRightsReserved';
import ThankYou from './components/ThankYou';

//styles
import { GlobalStyles } from './GlobalStyles';


const App = () => (
    <>
      <GlobalStyles />
      <Container>
      <TitleComponent />
      <Countdown />
      <Router>
        <Routes>
          <Route path="/" element={<Form />} />
          <Route path="/thank-you" element={<ThankYou />} />
        </Routes>
      </Router>
      <AllRightsReserved />
      </Container> 
    </>
     
  );

export default App;
