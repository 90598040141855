import { useState, useEffect, useCallback } from 'react';

const useCountdown = (targetDate) => {
  const calculateTimeLeft = useCallback(() => {
    const countDate = new Date(targetDate).getTime();
    const now = new Date().getTime();
    const gap = countDate - now;

    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;

    return {
      days: Math.floor(gap / day),
      hours: Math.floor((gap % day) / hour),
      minutes: Math.floor((gap % hour) / minute),
      seconds: Math.floor((gap % minute) / second),
    };
  }, [targetDate]);

  const [time, setTime] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [calculateTimeLeft]);

  return time;
};

export default useCountdown;