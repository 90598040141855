import styled from "styled-components";

export const Wrapper = styled.div`
    margin: 0 auto;
    max-width: var(--maxWidth);
    padding: calc(var(--baseSize) * 10);

    @media (max-width: var(--tabletView)) {
       margin: calc(var(--baseSize) * 10) 0;
       max-width: 100%;
        
    }
    @media (max-width: var(--mobileView)) {
        max-width: 100%;
       margin: calc(var(--baseSize) * 10) 0;
    }

`;
