import React from "react";

import { Title, Subtitle } from './Title.styles';

const TitleComponent = () => (
        <>
        <Title>Coming Soon!</Title>
        <Subtitle>Our website is under construction. We'll be here soon with our new awesome site.</Subtitle>
        </>
    );

export default TitleComponent;