import styled from "styled-components";

export const FormWrapper = styled.form`
    margin-top: calc(var(--baseSize) * 10);

    p {
        color: var(--errorRed);    
        margin-top: 0;
        margin-bottom: var(--baseSize);
    }    

    input::placeholder,
    textarea::placeholder {
        color: var(--white);
    }
    input, textarea {
        margin-top: calc(var(--baseSize) * 4);
        margin-bottom: calc(var(--baseSize) * 4);
        background: transparent;
        border: 2px solid var(--white);
        padding: 10px 20px;
    
        color: var(--white);
        font-size: calc(var(--baseSize) * 3.6);
        font-weight: calc(var(--baseWeight) * 2);
        width: 100%;
    
        &:focus {
            border-color: var(--white);
            outline: none;
        }
    }

    input {
        height: calc(var(--baseSize) * 10);
        border-radius: calc(var(--baseSize) * 10);
    }

    textarea {
        height: calc(var(--baseSize) * 40);
        border-top-right-radius: var(--baseSize);
        border-bottom-right-radius: var(--baseSize);
        border-top-left-radius: var(--baseSize);
        border-bottom-left-radius: var(--baseSize);
        padding: calc(var(--baseSize) * 4);
        
    }

`;

export const WrapperButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledButton = styled.button`
  background-color: var(--white);
  color: var(--black);
  font-size: calc(var(--baseSize) * 3.6);
  font-weight: calc(var(--baseWeight) * 2);
  padding: 10px 20px;
  border: 2px solid var(--white);
  border-radius: calc(var(--baseSize) * 10);
  cursor: pointer;
  margin-top: calc(var(--baseSize) * 4);
  margin-bottom: calc(var(--baseSize) * 4);
  max-width: 300px;
  width: 300px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #f3f3f3;
    color: var(--black);
  }

  @media (max-width: 480px) {
    max-width: 100%;
    width: 100%;
    
  }
`;