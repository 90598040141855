import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
    :root {
        --errorRed: #ff0000;
        --white: #ffffff;
        --black: #000000;
        --purple: #670acb;
        --lightPurple: #b825fc;
        --blue: #257ffc;
        --successGreen: #008000;
        --lightGrey: #eeeeee;
        --medGrey: #353535;
        --baseSize: 5px;
        --baseWeight: 100;
        --tabletView: 768px;
        --mobileView: 480px;
        --maxWidth: 946px;
    }

    * {
        box-sizing: border-box;
        font-family: 'Abel', sans-serif;
    }
    
    body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: 'Abel', sans-serif;
    background-color: var(--blue); /* Fallback solid color */
    background: linear-gradient(45deg, var(--blue), var(--purple), var(--lightPurple));
    background-size: 600% 600%;
    animation: gradientAnimation 15s ease infinite;

    @media (max-width: 998px) {
        display: block;
            
        }
    }

    @keyframes gradientAnimation {
        0% {
            background-position: 0% 50%;
        }
        33% {
            background-position: 50% 50%;
        }
        66% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
`;
