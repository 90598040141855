import React from 'react';
import styled from 'styled-components';

const StyledH2 = styled.h2`
    color: var(--white);
    font-size: 3.4rem;
    text-align: center;
    margin-top: calc(var(--baseSize) * 10);
`;
const StyledP = styled.p`
    color: var(--white);
    font-size: 1.6rem;
    text-align: center;
`;

const ThankYou = () => {
  return (
    <>
      <StyledH2>Thank You!</StyledH2>
      <StyledP>Your message has been sent successfully.</StyledP>
    </>
  );
};

export default ThankYou;