import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
//components
import LoadingSpinner from '../Spinner';
//hooks
import useFormValidation from '../../hooks/useFormValidation';
//styles
import { FormWrapper, WrapperButton, StyledButton } from './Form.styles';

const initialState = {
    name: '',
    email: '',
    message: ''
  };
  
  const validate = (values) => {
    let errors = {};
    
    if (!values.name) {
      errors.name = 'Name is required.';
    } else if (values.name.length < 3) {
      errors.name = 'Name must be at least 3 characters long.';
    } 
  
    if (!values.email) {
      errors.email = 'Email is required.';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
      errors.email = 'Email address is invalid.';
    } 
  
    if (!values.message) {
      errors.message = 'Message is required.';
    } else if (values.message.length < 5) {
      errors.message = 'Message must be at least 5 characters long.';
    } 
  
    return errors;
  };

const Form = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const { values, errors, handleChange, handleSubmit } = useFormValidation(initialState, validate, async () => {
      setLoading(true);
        try {
          const response = await fetch('send_email.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams(values).toString(),
          });
          
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          await response.text();
          navigate('/thank-you');
          
        } catch (error) {
          console.error('Error sending email:', error);
        } finally {
          setLoading(false);
        }
      });

    return (
        <FormWrapper 
        onSubmit={handleSubmit}>
            <>
                <input 
                    type='text' 
                    id='name' 
                    name='name' 
                    placeholder='Name' 
                    value={values.name} 
                    onChange={handleChange} 
                />
                {errors.name && <p>{errors.name}</p>}
            </>
            <>
                <input 
                    type='email' 
                    id='email' 
                    name='email' 
                    placeholder='Email Address' 
                    value={values.email} 
                    onChange={handleChange} 
                />
                {errors.email && <p>{errors.email}</p>}
            </>
            <>
                <textarea 
                    id='message' 
                    name='message' 
                    placeholder='Drop us a message!'
                    value={values.message} 
                    onChange={handleChange}
                />
                {errors.message && <p>{errors.message}</p>}
            </>
            <WrapperButton>
                <StyledButton type='submit' disabled={loading}>
                  {loading ? <LoadingSpinner /> : 'Notify Me'}
                </StyledButton>
            </WrapperButton>
        </FormWrapper>
    );
}

export default Form;