import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: calc(var(--baseSize) * 12) ;
    margin-bottom: calc(var(--baseSize) * 4);
`;

export const Content = styled.div`
    color: var(--white);
    font-size: 1.4rem;
    font-weight: calc(var(--baseWeight) * 3);
    font-style: normal;
    text-align: center;

`;